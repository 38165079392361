<template>
  <div>
    <a-modal :visible="visible" title="打印" :destroyOnClose="true" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-model-item label="打印文本">
          <a-input v-model="printText" />
        </a-form-model-item>
        <a-form-model-item label="打印次数">
          <a-input-number v-model="printCount" style="width: 100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getLodop } from "@/utils/LodopFuncs";

export default {
  props: ["visible", "addressText"],
  model: { prop: "visible", event: "cancel" },
  computed: {
    addressLabelWidth() {
      return this.$store.state.user.barConfig.addressLabelWidth;
    },
    addressLabelLength() {
      return this.$store.state.user.barConfig.addressLabelLength;
    },
  },
  data() {
    return {
      printText: "",
      printCount: 1,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel", false);
    },
    confirm() {
      let content = `
      <div style="padding: 8px; font-size: 24px; font-weight: bold;">${this.printText}</div>
      `;
      const LODOP = getLodop();
      LODOP.PRINT_INIT("Print Address");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.addressLabelLength}mm`, `${this.addressLabelWidth}mm`);

      for (let index = 0; index < this.printCount; index++) {
        LODOP.NewPage();
        LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", content);
      }
      LODOP.PREVIEW();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.printCount = 1;
        this.printText = this.addressText;
      }
    },
  },
};
</script>

<style scoped></style>
